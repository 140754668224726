.error {
  color: var(--theme-primary);
  width: var(--content-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 5rem auto;
}

.search-results-preview .error {
  width: auto;
  margin: 0;
}

.error-titre {
  font-size: 3.125rem;
  text-align: center;
}

.search-results-preview .error-titre {
  font-size: 2.5rem;
}

.error-sous-titre {
  font-size: 16rem;
  color: var(--theme-secondary);
  background-color: var(--theme-on-secondary);
  position: relative;
}

.search-results-preview .error-sous-titre {
  font-size: 12.5rem;
}

.error-popup {
  display: none;
  color: var(--theme-on-primary);
  background-color: var(--theme-primary);
  font-size: 1rem;
  width: 31rem;
  padding: 1em 2em;
  position: absolute;
  top: 50%;
  right: 35%;
}

.error-popup:hover,
.error-hover-element:hover + .error-popup {
  display: block;
}

.error-content {
  font-size: 2rem;
  padding: 1em 5em;
  text-align: justify;
}

.search-results-preview .error-content {
  font-size: 1.125rem;
}
